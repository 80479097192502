<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="alarmRecords/callRecord"
      @clear="clearDateRange">
      <template slot="adSearch">
        <vm-search :label="$t('event.dtuNumber')">
          <el-input v-model.trim="search.dtuCode" :placeholder="$t('event.dtuNumber')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('event.callTime')">
          <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
            :unlink-panels="true" :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']" @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <table-column prop="elevatorNo" :label="$t('event.elevatorNo')" :width="125" align="center">
        <template #default="scope">
          <router-link :to="'/elevator/' + scope.row.elevatorId" target="_blank">{{ scope.row.elevatorNo
            }}</router-link>
        </template>
      </table-column>
      <table-column prop="elevatorName" :label="$t('event.elevatorName')"></table-column>
      <table-column prop="dtuCode" :label="$t('event.dtuNumber')" width="130px" align="center"></table-column>
      <table-column prop="nodeCode" :label="$t('event.node')" width="90px" align="center"></table-column>
      <table-column prop="callTime" :label="$t('event.callTime')" width="160px" align="center"></table-column>
      <table-column prop="callDuration" :label="$t('event.callDuration')" :width="$l('120px', '90px')"
        align="center">
        <template #default="scope">
          <span v-if="scope.row.callDuration !== null">
            {{ scope.row.callDuration + $t("common.seconds") }}
          </span>
        </template>
      </table-column>
      <table-column prop="originator" :label="$t('event.originator')" align="center"></table-column>
      <table-column prop="connector" :label="$t('event.connector')" align="center"></table-column>
      <table-column prop="phone" :label="$t('event.phone')" align="center"></table-column>
      <table-column prop="createTime" :label="$t('common.createTime')" width="160px"
        align="center"></table-column>
      <table-column v-if="editAuth" :label="$t('common.operation')" width="160px" align="center" fixed="right" :tooltip="false">
        <template #default="scope">
          <el-button type="text" class="warningBtn" :loading="scope.row.loading" @click="handleSetMG(scope.row)">
            {{ $t("event.reset") }}
          </el-button>
          <el-button type="text" class="warningBtn" v-if="scope.row.recordPath" @click="handleAudio(scope.row)">
            {{ $t("event.audio") }}
          </el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete") }}
          </el-button>
        </template>
      </table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList(1)"></edit-dialog>
  </div>
</template>
<script>
import EditDialog from "@/views/event/CallRecordAudioDialog.vue";

export default {
  components: { EditDialog },
  data() {
    return {
      showDialog: false,
      audioUrl: "", // 音频文件的路径
      editAuth: this.$auth(342),
      loading: false,
      dateRange: [],
      search: {
        filter: "",
        startTime: "",
        endTime: "",
        dtuCode: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageIndex) {
      this.loading = true;
      this.$refs.vmTable.getList(pageIndex);
      this.loading = false;
    },
    handleSetMG(row) {
      this.$set(row, "loading", true);
      let params = {
        dtuCode: row.dtuCode,
        nodeCode: row.nodeCode,
      };
      this.$http.get("remote/setMG", params).then(res => {
        this.$set(row, "loading", false);
        this.$message.success(this.$t("elevator.tip.operateSuccess"));
      }).catch((error) => {
        this.$set(row, "loading", false);
        this.$message.error(this.$t("elevator.tip.operateError") + ", " + error.response.data.message);
      });
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("event.elevatorNo") + " " + row.elevatorNo + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById("alarmRecords/callRecord", row.id).then(res => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.deleteError") + "," + error.response.data.message);
          }
        });
      });
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    clearDateRange() {
      this.dateRange = [];
    },

    handleAudio(row) {
      this.$refs.editPage.open(row.id);
      /*this.$api.exportFile("alarmRecords/getAudio/" + row.id, null, 60).then(res => {
        if (res.data != null) {
          this.audioUrl = URL.createObjectURL(new Blob([res.data]));
          this.showDialog = true;
        }
      }).catch((error) => {
        this.$message.error(error);
      });*/
    },
  },
};
</script>
<!--<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px black;
}
</style>-->
